/*
 * @Author: TianJi
 * @Date: 2023-10-13 17:05:20
 * @LastEditors: luoyaooo
 * @LastEditTime: 2024-03-14 18:20:42
 * @Description:
 */
import Vue from "vue";
import bncBizUiFooter from "bnc-ui-footer";
import bncMobileMgr from "bnc-mobile-mgr";
console.log(urls);
new Vue({
    el: "#vueBox",
    data: {
        isleft: true,
        activeTitle: "最新动态",
        currentProjectEnv: "bnc",
        bizIndexInfo: {
            welcome: "",
            platform: "",
            description: "",
            bgImg: "",
        },
        imgArr: ["", ""],
        textContentData: {
            bgText: {},
            cPicArr: {},
            industryData: {},
            navData: {},
            talkData: {},
        },
    },
    created() {
        this.currentProjectEnv = urls.currentProjectEnv;
        document.title = "分中心";

        // switch (this.currentProjectEnv) {
        //     case "anxun":
        //         this.activeTitle = "安迅";
        //         this.textContentData = anxunTextContent();
        //         break;
        //     case "dfyx":
        //         this.textContentData = dfyxTextContent();
        //         break;
        //     case "light":
        //         this.textContentData = lightTextContent();
        //         document.title = "吉灵网络";
        //         break;
        //     case "gongfu":
        //     case "jiuzai":
        //         this.textContentData = window[`${this.currentProjectEnv}TextContent`]();
        //         break;
        //     case "huanke":
        //         this.textContentData = window[`${this.currentProjectEnv}TextContent`]();
        //         document.title = "碳服天下-浙江博信数智科技有限公司";
        //         break;
        //     default:
        //         this.textContentData = bncTextContent();
        //         break;
        // }
        // 项目化控制
        // let currentProjectEnv = this.currentProjectEnv;
        // // currentProjectEnv = "light";
        // switch (currentProjectEnv) {
        //     case "bnc":
        //     case "dfyx":
        //         // 暂时隐藏
        //         // $(".footTop, .q_concent").css({
        //         //     display: "block",
        //         // });
        //         break;
        //     default:
        //         break;
        // }
    },
    methods: {
        changeTitle(item, index) {
            function shuffle(arr) {
                arr.sort(() => Math.random() - 0.5);
            }
            this.activeTitle = item;
            if (this.currentProjectEnv !== "light") {
                let datas = this.textContentData.talkData.datas;
                shuffle(datas);
                this.textContentData.talkData.datas = datas;
            } else {
                this.textContentData.talkData.datas = this.textContentData.talkData.othertalkData[index];
            }
        },
        findMore() {
            let datas = this.textContentData.talkData.datas;
            let data = datas.slice(0, 5);
            let dataa = datas.concat(data);
            this.textContentData.talkData.datas = dataa;
        },
        changePage(title) {
            let cPicArr = this.textContentData.cPicArr;
            cPicArr = cPicArr.reverse();
            if (title === "left") {
                this.isleft = true;
            } else {
                this.isleft = false;

                this.textContentData.cPicArr = cPicArr;
            }
        },
        goIndustrial() {
            if (urls.currentProjectEnv == "light") {
                window.location.href = "../industrial-map-light/index.html";
            } else {
                window.location.href = "../industrial-map/index.html";
            }
        },
        getConfig() {
            const info = JSON.parse(localStorage.getItem("company-info"));
            if (info?.bizIndexInfo) {
                this.bizIndexInfo = info.bizIndexInfo;
                $(".navPic").css("background-image", `url(${info.bizIndexInfo.bgImg})`);
                return;
            }
            const self = this;
            $.ajax({
                type: "GET",
                url: "/bnc_public/config.json",
                success: function (res) {
                    if (res.bizIndexInfo) {
                        self.bizIndexInfo = res.bizIndexInfo;
                        $(".navPic").css("background-image", `url(${self.bizIndexInfo.bgImg})`);
                    }
                },
            });
        },
    },
    mounted() {
        bncBizUiFooter.initPageDom();
        this.getConfig(); // 文案、背景图
        bncMobileMgr.showAppDownloadQRCode("qrcode0", "qrcode1");
    },
});
